import React from 'react';
import Loadable from 'react-loadable';
import ItemVisual from '../components/item-visual/ItemVisual';
import styles from './404.module.scss';

const PageWithSubNav = Loadable({
  loader: () => import('../components/page-with-subnav/PageWithSubNav'),
  loading: () => null,
});

const Headline = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Headline;
    return <Component {...props} />;
  },
});

const Subhead = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Subhead;
    return <Component {...props} />;
  },
});

const componentArray = [
  {
    text: 'Action List',
    shape: 'triangle',
    hatching: 'hatchhexagon',
    illo: 'actionlist',
    path: 'components/action-list',
  },
  {
    text: 'User Avatar',
    shape: 'triangle',
    hatching: 'hatchcircle',
    illo: 'useravatar',
    path: 'components/avatars/user',
  },
  {
    text: 'Card',
    shape: 'parallelogram',
    hatching: 'hatchsashthin',
    illo: 'card',
    path: 'components/card',
  },
  {
    text: 'Modal',
    shape: 'circle',
    hatching: 'hatchsashthin',
    illo: 'modal',
    path: 'components/modal',
  },
  {
    text: 'Search Input',
    shape: 'hexagon',
    hatching: 'hatchrectanglesmall',
    illo: 'searchinput',
    path: 'components/forms/search-input',
  },
  {
    text: 'Note',
    shape: 'diamond',
    hatching: 'hatchparallelogram',
    illo: 'note',
    path: 'components/notifications/note',
  },
  {
    text: 'Spinner',
    shape: 'parallelogram',
    hatching: 'hatchsashthin',
    illo: 'spinner',
    path: 'components/spinner',
  },
];

const selectedComponent =
  componentArray[Math.floor(Math.random() * componentArray.length)];

const NotFoundPage = () => (
  <PageWithSubNav ignoreSearch>
    <div className={styles.wrapper}>
      <Headline level="1">404</Headline>
      <Subhead level="subtle" className="uni-margin--two--vertical">
        Sorry, that page doesn't exist. Why don't you check out the...
      </Subhead>
      <ItemVisual
        svgShape={selectedComponent.shape}
        svgHatching={selectedComponent.hatching}
        svgIllustration={selectedComponent.illo}
        text={selectedComponent.text}
        path={`/${selectedComponent.path}`}
      />
    </div>
  </PageWithSubNav>
);

export default NotFoundPage;
